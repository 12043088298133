import React from 'react'
import {Icon, Popup} from 'semantic-ui-react'
import {isCustomer, isWarehouse} from './is-role.js'
import {getPonumberContent, longDash} from './get-order-panels/get-ponumber-content.js'
import {formatDate} from './format-date.js'
import {getSalesOrders, getSalesOrdersFiltered, getSalesOrdersFilteredByStyleNumber} from './sales-orders/get'

const doingPONumberSearch = (myProps) => {
  return myProps.searchPONumber && myProps.searchString.length > 2;
}

const getRepText = (order) => {
  let rep = order.rep
  let repText
  if (rep == null) {
    rep = 'UNDEFINED'
  } else {
    repText = <span style={{whiteSpace: 'nowrap'}}>{longDash} {rep.replace('@ihlgroup.com', '').replace('@megabrandsgroup.com', '')}</span>
  }
  return {rep, repText}
}

let truckIconStyle = {
  color: 'rgb(242, 160, 128)',
  cursor: 'pointer',
  border: '1px solid rgb(212,212,212)',
  borderRadius: '8px',
  padding: '1px 4px',
  marginTop: '2px',
  display: 'inline-block'
}
let exfactoryIcon = <span style={truckIconStyle}><Icon name='factory' /><b>Ex-Factory</b></span>

const exfactoryContent = (date, vponumber, loadingDate) => {
  return (
    <span style={{whiteSpace: 'nowrap'}}>{longDash}<Popup position='bottom center' trigger={exfactoryIcon}><Popup.Header style={{color: 'rgb(242, 160, 128)'}}><Icon name='factory' /> Ex-Factory</Popup.Header><Popup.Content>This order has Ex-Factory {formatDate(date)}, Vendor PO# {vponumber}, Loading Date {loadingDate == null ? '—' : formatDate(loadingDate)}</Popup.Content></Popup></span>
  )
}

const skipOrder = (myState, order) => {
  let result = false

  //only skip order if using a tag, and doesn't match any
  let usingCheckbox = myState.oversold || myState.hasVponumber || myState.hasVpoLoadingDate

  let matchOversold = myState.oversold && !!order.oversold
  let matchVponumber = myState.hasVponumber && !!order.vendor_ponumber
  let matchLoadingDate = myState.hasVpoLoadingDate && !!order.loading_date
  let anyMatches = matchOversold || matchVponumber || matchLoadingDate

  if (usingCheckbox && !anyMatches) { result = true }

  //skip if zero quantity
  if ([0,'0'].includes(order.quantity)) { result = true }

  return result
}

const getOrderPanels = (myState, myProps, styleNumber, cadId, canSeePrice) => {
  let role = sessionStorage.getItem('currentUserRole')
  let isSales = ['sales','sales_assistant','inventory'].includes(role)
  let userEmail = myState.userEmail


  let useFilteredData = ["PO", "Hold", "Shipped", "Worksheet"].indexOf(myProps.cadReducer.cadStatus) > -1;
  let useUncombinedData = false;
  if (myProps.searchPONumber) {
    useUncombinedData = true;
    useFilteredData = false;
  }


  let {reps} = myState
  let filteringRep = reps.length > 0


  let sales_order_content = [];
  let sales_order_total = 0;

  let hold_content = [];
  let hold_total = 0;

  let worksheet_content = [];
  let worksheet_total = 0;


  let coronaIconStyle = {
    color: 'red',
    cursor: 'pointer',
    border: '1px solid rgb(212,212,212)',
    borderRadius: '8px',
    padding: '1px 4px',
    marginTop: '2px',
    display: 'inline-block'
  }


  let truckIcon = <span style={truckIconStyle}><Icon name='truck' /><b>RTS</b></span>
  let hourglassIcon = <span style={truckIconStyle}><Icon name='hourglass' /><b>PENDING</b></span>
  let coronaIcon = <span style={coronaIconStyle}><Icon name='warning sign' /><b>DELAY</b></span>
  let oversoldIcon = <span style={coronaIconStyle}><Icon name='warning sign' /><b>OVERSOLD</b></span>
  let upfrontIcon = <span style={truckIconStyle}><Icon name='rocket' /><b>UPFRONT</b></span>
  let fromStockIcon = <span style={truckIconStyle}><Icon name='hand paper outline' /><b>FROM STOCK</b></span>

  let orderRowStyle = {borderBottom: '1px solid rgb(212,212,212)', paddingBottom: '7px', lineHeight: '1.75em', fontSize: '90%', letterSpacing: '-0.6px'}
  let rtsContent = <span style={{whiteSpace: 'nowrap'}}>{longDash}<Popup position='bottom center' trigger={truckIcon}><Popup.Header style={{color: 'rgb(242, 160, 128)'}}><Icon name='truck' /> Ready to Ship</Popup.Header><Popup.Content>This order is Ready to Ship</Popup.Content></Popup></span>
  let pendingContent = <span style={{whiteSpace: 'nowrap'}}>{longDash}<Popup position='bottom center' trigger={hourglassIcon}><Popup.Header style={{color: 'rgb(242, 160, 128)'}}><Icon name='hourglass' /> Pending</Popup.Header><Popup.Content>This order is Pending status</Popup.Content></Popup></span>
  let oversoldContent = <span style={{whiteSpace: 'nowrap'}}>{longDash}<Popup position='bottom center' trigger={oversoldIcon}><Popup.Header style={{color: 'red'}}><Icon name='warning sign' /> Oversold</Popup.Header><Popup.Content>This order is Oversold</Popup.Content></Popup></span>


  let inProgressIcon = <span style={truckIconStyle}><span className="spinning-cog padding-left-5px"><Icon name='cog' /></span><b>IN PROGRESS</b></span>
  let inProgressContent = <span style={{whiteSpace: 'nowrap'}}>{longDash}<Popup position='bottom center' trigger={inProgressIcon}><Popup.Header style={{color: 'rgb(242, 160, 128)'}}><Icon name='cog' /> In Progress</Popup.Header><Popup.Content>This order is In Progress</Popup.Content></Popup></span>
  let upfrontContent = <span style={{whiteSpace: 'nowrap'}}>{longDash}<Popup position='bottom center' trigger={upfrontIcon}><Popup.Header style={{color: 'rgb(242, 160, 128)'}}><Icon name='rocket' /> Upfront</Popup.Header><Popup.Content>This order is Upfront</Popup.Content></Popup></span>
  let fromStockContent = <span style={{whiteSpace: 'nowrap'}}>{longDash}<Popup position='bottom center' trigger={fromStockIcon}><Popup.Header style={{color: 'rgb(242, 160, 128)'}}><Icon name='hand paper outline' /> From Stock</Popup.Header><Popup.Content>This order is From Stock</Popup.Content></Popup></span>

  if (myProps.salesHistoryAccount != '') {
    if (!!window.salesOrderDataFiltered) {

      let sales_orders = getSalesOrdersFiltered(styleNumber, cadId)
      if (!!sales_orders) {
        for (var i=0;i<sales_orders.length;i++) {
          let sales_order = sales_orders[i];
          let canSeePriceForAccount = false//this.canSeePriceForAccount(user_email, sales_order.account_name);
          let priceText = canSeePrice || canSeePriceForAccount ? <span style={{whiteSpace: 'nowrap'}}>{longDash} ${sales_order.sales_price}</span> : '';
          let priceTextExport = canSeePrice || canSeePriceForAccount ? '  —  $' + sales_order.sales_price : '';
          let sales_price = ''
          if (canSeePrice || canSeePriceForAccount) {
            sales_price = '$'+sales_order.sales_price
          }
          let marginText = sales_order['margin'] == null ? '' : <span style={{whiteSpace: 'nowrap'}}>{longDash} {sales_order['margin']*100}%</span>


          let {rep, repText} = getRepText(sales_order)
          //skip if unfilteredData but using rep filter
          if (!useFilteredData && filteringRep) {
            if (reps.indexOf(rep) == -1) {continue}
          }


          if (!!sales_order.hold) {
            if (!myProps.cadReducer.showHold) { continue }

            if (skipOrder(myState, sales_order)) { continue }

            if (window.hold_export_rows[cadId] == null) {
              window.hold_export_rows[cadId] = []
            }

            let startship = formatDate(sales_order.date)

            hold_content.push(
              <div style={orderRowStyle}>
                {'H: ' + sales_order.quantity} <span style={{whiteSpace: 'nowrap'}}>{longDash} {'ship: ' + startship}</span> <span style={{whiteSpace: 'nowrap'}}>{longDash} {'created: ' + formatDate(sales_order.date_was_entered)}</span> {getPonumberContent(sales_order)} {priceText} {marginText} {repText}

                {!!sales_order.rts ? rtsContent : null}

                {!!sales_order.pending ? pendingContent : null}

                {!!sales_order.oversold ? oversoldContent : null}

                {!!sales_order.ex_factory_date ? exfactoryContent(sales_order.ex_factory_date, sales_order.vendor_ponumber, sales_order.loading_date) : null}

                {sales_order.upfront == 'YES' ? upfrontContent : null}
                {sales_order.upfront == 'NO' ? fromStockContent : null}
              </div>
            );
            window.hold_export_rows[cadId].push(sales_order.quantity + '  —  ' + formatDate(sales_order.date) + priceTextExport);

            hold_total += Number(sales_order.quantity);

            if (window.hold_ponumbers[cadId] == null) {
              window.hold_ponumbers[cadId] = sales_order.ponumber
            } else {
              window.hold_ponumbers[cadId] += '|||' + sales_order.ponumber
            }

            if (window.hold_startship[cadId] == null) {
              window.hold_startship[cadId] = startship
            } else {
              window.hold_startship[cadId] += '|||' + startship
            }

            if (window.hold_account[cadId] == null) {
              window.hold_account[cadId] = myProps.salesHistoryAccount
            } else {
              window.hold_account[cadId] += '|||' + myProps.salesHistoryAccount
            }

            if (window.hold_price[cadId] == null) {
              window.hold_price[cadId] = sales_price
            } else {
              window.hold_price[cadId] += '|||' + sales_price
            }

          } else if (!!sales_order.worksheet) {
            if (!myProps.cadReducer.showWorksheet) { continue }

            if (skipOrder(myState, sales_order)) { continue }

            if (window.worksheet_export_rows[cadId] == null) {
              window.worksheet_export_rows[cadId] = []
            }

            let startship = formatDate(sales_order.date)

            worksheet_content.push(
              <div style={orderRowStyle}>
                {'W: ' + sales_order.quantity} <span style={{whiteSpace: 'nowrap'}}>{longDash} {startship}</span> {getPonumberContent(sales_order)} {priceText} {marginText} {repText}

                {!!sales_order.rts ? rtsContent : null}

                {!!sales_order.pending ? pendingContent : null}

                {!!sales_order.oversold ? oversoldContent : null}

                {!!sales_order.ex_factory_date ? exfactoryContent(sales_order.ex_factory_date, sales_order.vendor_ponumber, sales_order.loading_date) : null}

                {sales_order.upfront == 'YES' ? upfrontContent : null}
                {sales_order.upfront == 'NO' ? fromStockContent : null}
              </div>
            );
            window.worksheet_export_rows[cadId].push(sales_order.quantity + '  —  ' + formatDate(sales_order.date) + priceTextExport);

            worksheet_total += Number(sales_order.quantity);

            if (window.worksheet_ponumbers[cadId] == null) {
              window.worksheet_ponumbers[cadId] = sales_order.ponumber
            } else {
              window.worksheet_ponumbers[cadId] += '|||' + sales_order.ponumber
            }

            if (window.worksheet_startship[cadId] == null) {
              window.worksheet_startship[cadId] = startship
            } else {
              window.worksheet_startship[cadId] += '|||' + startship
            }

            if (window.worksheet_account[cadId] == null) {
              window.worksheet_account[cadId] = myProps.salesHistoryAccount
            } else {
              window.worksheet_account[cadId] += '|||' + myProps.salesHistoryAccount
            }

            if (window.worksheet_price[cadId] == null) {
              window.worksheet_price[cadId] = sales_price
            } else {
              window.worksheet_price[cadId] += '|||' + sales_price
            }

          } else {
            //is sales order
            if (!myProps.cadReducer.showPO) { continue }

            if (skipOrder(myState, sales_order)) { continue }

            if (window.sales_order_export_rows[cadId] == null) {
              window.sales_order_export_rows[cadId] = []
            }

            let startship = formatDate(sales_order.date)

            sales_order_content.push(
              <div style={orderRowStyle}>
                {'S: ' + sales_order.quantity} <span style={{whiteSpace: 'nowrap'}}>{longDash} {startship}</span> {getPonumberContent(sales_order)} {priceText} {marginText} {repText}

                {!!sales_order.rts ? rtsContent : null}

                {!!sales_order.pending ? pendingContent : null}

                {!!sales_order.oversold ? oversoldContent : null}

                {!!sales_order.ex_factory_date ? exfactoryContent(sales_order.ex_factory_date, sales_order.vendor_ponumber, sales_order.loading_date) : null}

                {sales_order.upfront == 'YES' ? upfrontContent : null}
                {sales_order.upfront == 'NO' ? fromStockContent : null}
              </div>
            );
            window.sales_order_export_rows[cadId].push(sales_order.quantity + '  —  ' + formatDate(sales_order.date) + priceTextExport);

            sales_order_total += Number(sales_order.quantity);

            if (window.sales_order_ponumbers[cadId] == null) {
              window.sales_order_ponumbers[cadId] = sales_order.ponumber
            } else {
              window.sales_order_ponumbers[cadId] += '|||' + sales_order.ponumber
            }

            if (window.sales_order_startship[cadId] == null) {
              window.sales_order_startship[cadId] = startship
            } else {
              window.sales_order_startship[cadId] += '|||' + startship
            }

            if (window.sales_order_account[cadId] == null) {
              window.sales_order_account[cadId] = myProps.salesHistoryAccount
            } else {
              window.sales_order_account[cadId] += '|||' + myProps.salesHistoryAccount
            }

            if (window.sales_order_price[cadId] == null) {
              window.sales_order_price[cadId] = sales_price
            } else {
              window.sales_order_price[cadId] += '|||' + sales_price
            }

          }
        }
      }
    }
  } else {
    if (useFilteredData ? !!window.salesOrderDataFilteredByStyleNumber : !!window.salesOrderDataByStyleNumber) {

      let sales_orders

      if (useFilteredData) {
        sales_orders = getSalesOrdersFilteredByStyleNumber(styleNumber, cadId)
      } else {
        sales_orders = getSalesOrders(styleNumber, cadId)
      }

      if (!!sales_orders) {
        for (var i=0;i<sales_orders.length;i++) {
          let sales_order = sales_orders[i];
          let ponumber = (sales_order.ponumber == null ? null : sales_order.ponumber.trimUpcase())
          let showSalesOrder = doingPONumberSearch(myProps) ? ponumber == window.searchPoNumber : true;
          let canSeePriceForAccount = false//this.canSeePriceForAccount(user_email, sales_order.account_name);
          let priceText = canSeePrice || canSeePriceForAccount ? <span style={{whiteSpace: 'nowrap'}}>{longDash} ${sales_order.sales_price}</span> : '';
          let priceTextExport = canSeePrice || canSeePriceForAccount ? '  —  $' + sales_order.sales_price : '';
          let marginText = sales_order['margin'] == null ? '' : <span style={{whiteSpace: 'nowrap'}}>{longDash} {sales_order['margin']*100}%</span>

          let sales_price = ''
          if (canSeePrice || canSeePriceForAccount) {
            sales_price = '$'+sales_order.sales_price
          }


          let {rep, repText} = getRepText(sales_order)
          //skip if unfilteredData but using rep filter
          if (!useFilteredData && filteringRep) {
            if (reps.indexOf(rep) == -1) {continue}
          }


          //specific to org 1 hide LOST po# in front end
          if (ponumber == 'LOST') continue


          if (!showSalesOrder) {
            continue;
          }

          if (myProps.cadReducer.defaultAccount.length > 0 && !myProps.cadReducer.defaultAccount.includes(sales_order.account_name)) {
            continue;
          }

          let first5_of_account_name = (sales_order.account_name == null ? null : sales_order.account_name.substring(0, 5) )

          if (!!sales_order.hold) {
            if (!myProps.cadReducer.showHold) { continue }

            if (skipOrder(myState, sales_order)) { continue }

            if (window.hold_export_rows[cadId] == null) {
              window.hold_export_rows[cadId] = []
            }

            let startship = formatDate(sales_order.date)

            hold_content.push(
              <div style={orderRowStyle}>
                {first5_of_account_name} {longDash} {'H: ' + sales_order.quantity} <span style={{whiteSpace: 'nowrap'}}>{longDash} {'ship: ' + startship}</span> <span style={{whiteSpace: 'nowrap'}}>{longDash} {'created: ' + formatDate(sales_order.date_was_entered)}</span> {getPonumberContent(sales_order)} {priceText} {marginText} {repText}

                {!!sales_order.rts ? rtsContent : null}

                {!!sales_order.pending ? pendingContent : null}

                {!!sales_order.oversold ? oversoldContent : null}

                {!!sales_order.ex_factory_date ? exfactoryContent(sales_order.ex_factory_date, sales_order.vendor_ponumber, sales_order.loading_date) : null}

                {sales_order.upfront == 'YES' ? upfrontContent : null}
                {sales_order.upfront == 'NO' ? fromStockContent : null}
              </div>
            );
            window.hold_export_rows[cadId].push(sales_order.quantity + '  —  ' + formatDate(sales_order.date) + priceTextExport);

            hold_total += Number(sales_order.quantity);

            if (window.hold_ponumbers[cadId] == null) {
              window.hold_ponumbers[cadId] = sales_order.ponumber
            } else {
              window.hold_ponumbers[cadId] += '|||' + sales_order.ponumber
            }

            if (window.hold_startship[cadId] == null) {
              window.hold_startship[cadId] = startship
            } else {
              window.hold_startship[cadId] += '|||' + startship
            }

            if (window.hold_account[cadId] == null) {
              window.hold_account[cadId] = sales_order.account_name
            } else {
              window.hold_account[cadId] += '|||' + sales_order.account_name
            }

            if (window.hold_price[cadId] == null) {
              window.hold_price[cadId] = sales_price
            } else {
              window.hold_price[cadId] += '|||' + sales_price
            }

          } else if (!!sales_order.worksheet) {
            if (!myProps.cadReducer.showWorksheet) { continue }

            if (skipOrder(myState, sales_order)) { continue }

            if (window.worksheet_export_rows[cadId] == null) {
              window.worksheet_export_rows[cadId] = []
            }

            let startship = formatDate(sales_order.date)

            worksheet_content.push(
              <div style={orderRowStyle}>
                {first5_of_account_name} {longDash} {'W: ' + sales_order.quantity} <span style={{whiteSpace: 'nowrap'}}>{longDash} {startship}</span> {getPonumberContent(sales_order)} {priceText} {marginText} {repText}

                {!!sales_order.rts ? rtsContent : null}

                {!!sales_order.pending ? pendingContent : null}

                {!!sales_order.oversold ? oversoldContent : null}

                {!!sales_order.ex_factory_date ? exfactoryContent(sales_order.ex_factory_date, sales_order.vendor_ponumber, sales_order.loading_date) : null}

                {sales_order.upfront == 'YES' ? upfrontContent : null}
                {sales_order.upfront == 'NO' ? fromStockContent : null}
              </div>
            );
            window.worksheet_export_rows[cadId].push(sales_order.quantity + '  —  ' + formatDate(sales_order.date) + priceTextExport);

            worksheet_total += Number(sales_order.quantity);

            if (window.worksheet_ponumbers[cadId] == null) {
              window.worksheet_ponumbers[cadId] = sales_order.ponumber
            } else {
              window.worksheet_ponumbers[cadId] += '|||' + sales_order.ponumber
            }

            if (window.worksheet_startship[cadId] == null) {
              window.worksheet_startship[cadId] = startship
            } else {
              window.worksheet_startship[cadId] += '|||' + startship
            }

            if (window.worksheet_account[cadId] == null) {
              window.worksheet_account[cadId] = sales_order.account_name
            } else {
              window.worksheet_account[cadId] += '|||' + sales_order.account_name
            }

            if (window.worksheet_price[cadId] == null) {
              window.worksheet_price[cadId] = sales_price
            } else {
              window.worksheet_price[cadId] += '|||' + sales_price
            }

          } else {
            //is sales order
            if (!myProps.cadReducer.showPO) { continue }

            if (skipOrder(myState, sales_order)) { continue }

            if (window.sales_order_export_rows[cadId] == null) {
              window.sales_order_export_rows[cadId] = []
            }

            let startship = formatDate(sales_order.date)

            sales_order_content.push(
              <div style={orderRowStyle}>
                {first5_of_account_name} {longDash} {'S: ' + sales_order.quantity} <span style={{whiteSpace: 'nowrap'}}>{longDash} {startship}</span> {getPonumberContent(sales_order)} {priceText} {marginText} {repText}

                {!!sales_order.rts ? rtsContent : null}

                {!!sales_order.pending ? pendingContent : null}

                {!!sales_order.oversold ? oversoldContent : null}

                {!!sales_order.ex_factory_date ? exfactoryContent(sales_order.ex_factory_date, sales_order.vendor_ponumber, sales_order.loading_date) : null}

                {sales_order.upfront == 'YES' ? upfrontContent : null}
                {sales_order.upfront == 'NO' ? fromStockContent : null}
              </div>
            );
            sales_order_total += Number(sales_order.quantity);

            window.sales_order_export_rows[cadId].push(sales_order.quantity + '  —  ' + formatDate(sales_order.date) + priceTextExport);

            if (window.sales_order_ponumbers[cadId] == null) {
              window.sales_order_ponumbers[cadId] = sales_order.ponumber
            } else {
              window.sales_order_ponumbers[cadId] += '|||' + sales_order.ponumber
            }

            if (window.sales_order_startship[cadId] == null) {
              window.sales_order_startship[cadId] = startship
            } else {
              window.sales_order_startship[cadId] += '|||' + startship
            }

            if (window.sales_order_account[cadId] == null) {
              window.sales_order_account[cadId] = sales_order.account_name
            } else {
              window.sales_order_account[cadId] += '|||' + sales_order.account_name
            }

            if (window.sales_order_price[cadId] == null) {
              window.sales_order_price[cadId] = sales_price
            } else {
              window.sales_order_price[cadId] += '|||' + sales_price
            }

          }
        }
      }
    }
  }



  let shipped_content = [];
  let shipped_total = 0;

  if (myProps.salesHistoryAccount != '') {
    if (myProps.cadReducer.showShipped && !!window.salesAccountDataFiltered) {

      let shipped = window.salesAccountDataFiltered[styleNumber.flatUpcase()];
      if (!!shipped) {
        for (var i=0;i<shipped.length;i++) {
          let shipped_order = shipped[i];
          let canSeePriceForAccount = false//this.canSeePriceForAccount(user_email, shipped_order.account_name);
          let priceText = canSeePrice || canSeePriceForAccount ? <span style={{whiteSpace: 'nowrap'}}>{longDash} ${shipped_order.sales_price}</span> : '';
          let priceTextExport = canSeePrice || canSeePriceForAccount ? '  —  $' + shipped_order.sales_price : '';
          let marginText = shipped_order['margin'] == null ? '' : <span style={{whiteSpace: 'nowrap'}}>{longDash} {shipped_order['margin']*100}%</span>

          //skip if zero quantity
          if ([0,'0'].includes(shipped_order.quantity)) { continue }

          if (window.shipped_export_rows[cadId] == null) {
            window.shipped_export_rows[cadId] = [];
          }

          let ponumberText = '';
          if (shipped_order.ponumber != null) {
            ponumberText = <span style={{whiteSpace: 'nowrap'}}>{longDash} PO#: <u>{shipped_order.ponumber}</u></span>
          }


          let {rep, repText} = getRepText(shipped_order)
          //skip if unfilteredData but using rep filter
          if (!useFilteredData && filteringRep) {
            if (reps.indexOf(rep) == -1) {continue}
          }


          if (myState.inProgress && !shipped_order.in_progress) { continue }

          let startship = formatDate(shipped_order.date)

          shipped_content.push(
            <div style={orderRowStyle}>
              S: {shipped_order.quantity} {longDash} {startship} {ponumberText} {priceText} {marginText} {repText}

              {!!shipped_order.in_progress ? inProgressContent : null}

              {shipped_order.upfront == 'YES' ? upfrontContent : null}
              {shipped_order.upfront == 'NO' ? fromStockContent : null}
            </div>
          )
          window.shipped_export_rows[cadId].push(shipped_order.quantity + '  —  ' + formatDate(shipped_order.date) + priceTextExport);

          shipped_total += Number(shipped_order.quantity);

          let shipped_order_ponumber = shipped_order.ponumber;
          if (shipped_order_ponumber == null) {
            shipped_order_ponumber = '—'
          }

          if (window.shipped_ponumbers[cadId] == null) {
            window.shipped_ponumbers[cadId] = shipped_order_ponumber
          } else {
            window.shipped_ponumbers[cadId] += '|||' + shipped_order_ponumber
          }

          if (window.shipped_startship[cadId] == null) {
            window.shipped_startship[cadId] = startship
          } else {
            window.shipped_startship[cadId] += '|||' + startship
          }

          if (window.shipped_account[cadId] == null) {
            window.shipped_account[cadId] = myProps.salesHistoryAccount
          } else {
            window.shipped_account[cadId] += '|||' + myProps.salesHistoryAccount
          }

          let sales_price = ''
          if (canSeePrice || canSeePriceForAccount) {
            sales_price = '$'+shipped_order.sales_price
          }

          if (window.shipped_price[cadId] == null) {
            window.shipped_price[cadId] = sales_price
          } else {
            window.shipped_price[cadId] += '|||' + sales_price
          }

        }
      }
    }
  } else {
    if (myProps.cadReducer.showShipped && (useFilteredData ? !!window.salesAccountDataFilteredByStyleNumber : !!window.salesAccountDataByStyleNumber)) {

      let shipped;
      if (useFilteredData) {
        shipped = window.salesAccountDataFilteredByStyleNumber[styleNumber.flatUpcase()];

      } else {
        shipped = window.salesAccountDataByStyleNumber[styleNumber.flatUpcase()];
      }

      if (!!shipped) {

        for (var i=0;i<shipped.length;i++) {
          let shipped_order = shipped[i];
          let ponumber = shipped_order.ponumber.trimUpcase()
          let showShippedOrder = doingPONumberSearch(myProps) ? ponumber == window.searchPoNumber : true;
          let canSeePriceForAccount = false//this.canSeePriceForAccount(user_email, shipped_order.account_name);
          let priceText = canSeePrice || canSeePriceForAccount ? <span style={{whiteSpace: 'nowrap'}}>{longDash} ${shipped_order.sales_price}</span> : '';
          let priceTextExport = canSeePrice || canSeePriceForAccount ? '  —  $' + shipped_order.sales_price : '';
          let marginText = shipped_order['margin'] == null ? '' : <span style={{whiteSpace: 'nowrap'}}>{longDash} {shipped_order['margin']*100}%</span>

          if (!showShippedOrder) {
            continue
          }

          if (myProps.cadReducer.defaultAccount.length > 0 && !myProps.cadReducer.defaultAccount.includes(shipped_order.account_name)) {
            continue;
          }

          //skip if zero quantity
          if ([0,'0'].includes(shipped_order.quantity)) { continue }

          if (window.shipped_export_rows[cadId] == null) {
            window.shipped_export_rows[cadId] = [];
          }

          let {rep, repText} = getRepText(shipped_order)
          //skip if unfilteredData but using rep filter
          if (!useFilteredData && filteringRep) {
            if (reps.indexOf(rep) == -1) {continue}
          }


          let first5_of_account_name = (shipped_order.account_name == null ? null : shipped_order.account_name.substring(0, 5) )

          let ponumberText = '';
          if (shipped_order.ponumber != null) {
            ponumberText = <span style={{whiteSpace: 'nowrap'}}>{longDash} PO#: <u>{shipped_order.ponumber}</u></span>;
          }

          if (myState.inProgress && !shipped_order.in_progress) { continue }

          let startship = formatDate(shipped_order.date)

          shipped_content.push(
            <div style={orderRowStyle}>
              {first5_of_account_name} {longDash} S: {shipped_order.quantity} {longDash} {startship} {ponumberText} {priceText} {marginText} {repText}

              {!!shipped_order.in_progress ? inProgressContent : null}

              {shipped_order.upfront == 'YES' ? upfrontContent : null}
              {shipped_order.upfront == 'NO' ? fromStockContent : null}
            </div>
          )

          window.shipped_export_rows[cadId].push(shipped_order.quantity + '  —  ' + formatDate(shipped_order.date) + priceTextExport);

          shipped_total += Number(shipped_order.quantity);

          let shipped_order_ponumber = shipped_order.ponumber;
          if (shipped_order_ponumber == null) {
            shipped_order_ponumber = '—'
          }

          if (window.shipped_ponumbers[cadId] == null) {
            window.shipped_ponumbers[cadId] = shipped_order_ponumber
          } else {
            window.shipped_ponumbers[cadId] += '|||' + shipped_order_ponumber
          }

          if (window.shipped_startship[cadId] == null) {
            window.shipped_startship[cadId] = startship
          } else {
            window.shipped_startship[cadId] += '|||' + startship
          }

          if (window.shipped_account[cadId] == null) {
            window.shipped_account[cadId] = shipped_order.account_name
          } else {
            window.shipped_account[cadId] += '|||' + shipped_order.account_name
          }

          let sales_price = ''
          if (canSeePrice || canSeePriceForAccount) {
            sales_price = '$'+shipped_order.sales_price
          }

          if (window.shipped_price[cadId] == null) {
            window.shipped_price[cadId] = sales_price
          } else {
            window.shipped_price[cadId] += '|||' + sales_price
          }
        }
      }
    }
  }





  let panels = [];

  if (shipped_content.length > 0) {
    window.shipped_total[cadId] = shipped_total;
    let panel = {
      key: 'shipped',
      title: 'Shipped: ' + shipped_total,
      content: {
        content: (
          <div>
            {shipped_content.map(function(row, index) {
              return (<p key={index}>
                {row}
              </p>);
            })}
          </div>
        ),
      },
    }
    if (isCustomer() || isWarehouse()) {
      panel.active = true
    }
    panels.push(panel)
  }

  if (sales_order_content.length > 0) {
    window.sales_order_total[cadId] = sales_order_total;
    let panel = {
      key: 'sales-order',
      title: 'PO: ' + sales_order_total,
      content: {
        content: (
          <div>
            {sales_order_content.map(function(row, index) {
              return (<p key={index}>
                {row}
              </p>);
            })}
          </div>
        ),
      },
    }
    if (isCustomer() || isWarehouse()) {
      panel.active = true
    }
    panels.push(panel)
  }

  if (worksheet_content.length > 0) {
    window.worksheet_total[cadId] = worksheet_total;
    let panel = {
      key: 'worksheet',
      title: 'Worksheet: ' + worksheet_total,
      content: {
        content: (
          <div>
            {worksheet_content.map(function(row, index) {
              return (<p key={index}>
                {row}
              </p>);
            })}
          </div>
        ),
      },
    }
    if (isCustomer() || isWarehouse()) {
      panel.active = true
    }
    panels.push(panel)
  }

  if (hold_content.length > 0) {
    window.hold_total[cadId] = hold_total;
    let panel = {
      key: 'hold',
      title: 'Hold: ' + hold_total,
      content: {
        content: (
          <div>
            {hold_content.map(function(row, index) {
              return (<p key={index}>
                {row}
              </p>);
            })}
          </div>
        ),
      },
    }
    if (isCustomer() || isWarehouse()) {
      panel.active = true
    }
    panels.push(panel)
  }

  return panels;
}

export default getOrderPanels
