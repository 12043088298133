import React from 'react'
import {Dropdown, Icon, Popup, Modal, Button, Select} from 'semantic-ui-react'
import {isCustomer, isWarehouse, isDesign, isInventory, isProduction} from '../cads/is-role.js'
import {qcOptions} from '../../getApiEndpoint.js'
import DeleteCad from './delete-cad.js'
import {canEditCads, canDeleteCads} from '../custom-access/can'

const getHeader = (isAdmin, canApproveBrands, cad, cadCardEllipsis, handleCadCheckboxChange, showLinked, reportQcIssueModalOpen, reportQcIssueModalClose, qcOnChange, saveQcIssueModal, clearCadCard) => {

  let {isGuest} = window


  let optionsEllipsis = [{ key: 'View', text: 'View', value: 'View'}]

  if (canEditCads() || isAdmin) {
    optionsEllipsis.push({ key: 'Edit', text: 'Edit', value: 'Edit'})
  }

  if ((isAdmin || isDesign() || isInventory() || isProduction())) {
    // if (!cad.is_branded && !cad.is_licensee_approved) {
    //   optionsEllipsis.push({ key: 'Request Brand Approval', text: 'Request Brand Approval', value: 'Request Brand Approval'})
    // }

    if (isAdmin || canApproveBrands) {
      if (cad.is_branded) {
        optionsEllipsis.push({ key: 'Approve Brand', text: 'Approve Brand', value: 'Approve Brand'})
      }

      if (cad.is_branded || cad.is_licensee_approved) {
        optionsEllipsis.push({ key: 'Reject Brand', text: 'Reject Brand', value: 'Reject Brand'})
      }
    }

    if (cad.is_branded || cad.is_licensee_approved) {
      optionsEllipsis.push({ key: 'Request PP Samples', text: 'Request PP Samples', value: 'Request PP Samples'})

      if (cad.is_pp) {
        optionsEllipsis.push({ key: 'Approve PP Samples', text: 'Approve PP Samples', value: 'Approve PP Samples'})
      }

      if (cad.is_pp || cad.is_pp_approved) {
        optionsEllipsis.push({ key: 'Reject PP Samples', text: 'Reject PP Samples', value: 'Reject PP Samples'})
      }
    }






  }

  if (!isCustomer() && !isWarehouse()) {
    // optionsEllipsis.push({ key: 'Report QC', text: 'Report QC', value: 'Report QC' })
    optionsEllipsis.push({ key: 'Add to Line Sheet', text: 'Add to Line Sheet', value: 'Add to Line Sheet'})
  }

  return (
    <div className="header">
      {isGuest ? null :
        <div id="cad-options-dropdown">
          <Dropdown simple direction='left' icon='ellipsis horizontal' options={optionsEllipsis} item onChange={cadCardEllipsis.bind(this, cad)} value={null} />
        </div>
      }

      <div className="ui checkbox cad-checkbox-num" style={{width: '100%'}}>
        <input id={'cad-checkbox-'+cad.id} onChange={handleCadCheckboxChange.bind(this, cad.id)} type="checkbox" />
        <label for={'cad-checkbox-'+cad.id} style={cad.is_deleted ? {textDecoration: 'line-through'} : {cursor: 'pointer'}}>{cad.style_number}</label>
        {
          cad.family_id_total != null && cad.family_id_total > 1 && !isWarehouse()
          ?
          <Popup
            trigger={
              <Button onClick={showLinked.bind(this, cad.family_id)} basic icon style={{padding: '6px', paddingRight: 0, float: 'right'}}>
                <Icon name='linkify' /> {cad.family_id_total} &nbsp;&nbsp;
              </Button>
            }
          >
            <Popup.Header>Click to See Linked Cads</Popup.Header>
          </Popup>
          :
          null
        }
        {
          [null,0].includes(cad.available) && [null,0].includes(cad.future_stock) && (canDeleteCads() || isAdmin) && !cad.is_deleted
          ?
          <DeleteCad cadId={cad.id} styleNumber={cad.style_number} clearCadCard={clearCadCard}/>
          :
          null
        }
      </div>

      <Modal size={'small'} open={reportQcIssueModalOpen} onClose={reportQcIssueModalClose} closeIcon>
        <Modal.Header>Report QC: {window.reportQcIssueCadStyleNumber} <small style={{opacity: '0.5'}}>(cad id: {window.reportQcIssueCadId})</small></Modal.Header>
        <Modal.Content>
          <Select multiple fluid label='Select QC' onChange={qcOnChange} options={qcOptions} placeholder='Select QC' style={{width: '70%'}} />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={saveQcIssueModal} primary>
            Save QC
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  )
}

export default getHeader
