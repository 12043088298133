import {sumShipped, sumShippedDollar, sumSalesOrders, sumSalesOrdersDollar, sumHold, sumHoldDollar, sumWorksheet, sumWorksheetDollar} from './sum.js'

const updateCadsSalesHistoryByStyleNumber = (myState, cadReducer, that) => {
  let {reps} = myState

  let role = sessionStorage.getItem('currentUserRole')

  let filteringRep = reps.length > 0

  var dateFromData = cadReducer.dateFrom.split('/');
  var dateFromDate = new Date(dateFromData[2],dateFromData[0]-1,dateFromData[1]);
  var dateToData = cadReducer.dateTo.split('/');
  var dateToDate = new Date(dateToData[2],dateToData[0]-1,dateToData[1]);

  window.salesAccountDataFilteredByStyleNumber = [];
  window.salesOrderDataFilteredByStyleNumber = [];
  window.salesOrderDataFilteredByStyleNumber_NoHoldOrWorksheet = [];
  window.salesOrderDataFilteredByStyleNumber_Hold = [];
  window.salesOrderDataFilteredByStyleNumber_Worksheet = [];

  if (!!window.salesAccountDataByStyleNumber) {
    var salesAccountKeys = Object.keys(window.salesAccountDataByStyleNumber);

    for (var i=0; i<salesAccountKeys.length; i++) {
      var salesAccountKey = salesAccountKeys[i];

      var invoicesFiltered = [];
      var invoices = window.salesAccountDataByStyleNumber[salesAccountKey];
      for (var j=0; j< invoices.length; j++) {
        var invoice = invoices[j];
        var invoiceDateData = invoice.date.split('-');
        var invoiceDate = new Date(invoiceDateData[0],invoiceDateData[1]-1,invoiceDateData[2]);

        let rep = invoice.rep
        if (rep == null) {
          rep = 'UNDEFINED'
        }

        if (invoiceDate >= dateFromDate && invoiceDate <= dateToDate && (filteringRep ? reps.indexOf(rep) > -1 : true) && marginIsGood(cadReducer.margins, invoice['margin'])) {
          invoicesFiltered.push(invoice)
        }
      }

      if (invoicesFiltered.length > 0) {// set filtered invoices to keys in window.salesAccountDataFiltered
        window.salesAccountDataFilteredByStyleNumber[salesAccountKey] = invoicesFiltered
      }
    }

    //admin total
    if (role == 'admin' || role == "superadmin" || role == 'finance') {
      let shippedKeys = Object.keys(window.salesAccountDataFilteredByStyleNumber);
      window.shippedTotal = shippedKeys.reduce((a, b) => a + sumShipped(window.salesAccountDataFilteredByStyleNumber[b], cadReducer.defaultAccount) , 0);
      window.shippedTotalDollar = shippedKeys.reduce((a, b) => a + sumShippedDollar(window.salesAccountDataFilteredByStyleNumber[b], cadReducer.defaultAccount) , 0);
    }
  }

  //also do for sales orders

  if (!!window.salesOrderDataByStyleNumber) {
    var salesOrderKeys = Object.keys(window.salesOrderDataByStyleNumber);

    for (var i=0; i<salesOrderKeys.length; i++) {
      var salesOrderKey = salesOrderKeys[i];

      var invoicesFiltered = [];
      var invoices = window.salesOrderDataByStyleNumber[salesOrderKey];
      for (var j=0; j< invoices.length; j++) {
        var invoice = invoices[j];
        var invoiceDateData = invoice.date.split('-');
        var invoiceDate = new Date(invoiceDateData[0],invoiceDateData[1]-1,invoiceDateData[2]);

        let rep = invoice.rep
        if (rep == null) {
          rep = 'UNDEFINED'
        }

        if (invoiceDate >= dateFromDate && invoiceDate <= dateToDate && (filteringRep ? reps.indexOf(rep) > -1 : true) && marginIsGood(cadReducer.margins, invoice['margin'])) {
          invoicesFiltered.push(invoice)
          if (!invoice.hold && !invoice.worksheet) {
            window.salesOrderDataFilteredByStyleNumber_NoHoldOrWorksheet[salesOrderKey] = true;
          } else if (!!invoice.hold) {
            window.salesOrderDataFilteredByStyleNumber_Hold[salesOrderKey] = true;
          } else {
            window.salesOrderDataFilteredByStyleNumber_Worksheet[salesOrderKey] = true;
          }
        }
      }

      if (invoicesFiltered.length > 0) {// set filtered invoices to keys in window.salesAccountDataFiltered
        window.salesOrderDataFilteredByStyleNumber[salesOrderKey] = invoicesFiltered;
      }
    }

    //admin total
    if (role == 'admin' || role == "superadmin" || role == 'finance') {
      let salesOrderKeys = Object.keys(window.salesOrderDataFilteredByStyleNumber);
      window.salesOrderTotal = salesOrderKeys.reduce((a, b) => a + sumSalesOrders(window.salesOrderDataFilteredByStyleNumber[b], cadReducer.defaultAccount) , 0);
      window.salesOrderTotalDollar = salesOrderKeys.reduce((a, b) => a + sumSalesOrdersDollar(window.salesOrderDataFilteredByStyleNumber[b], cadReducer.defaultAccount) , 0);
      window.holdTotal = salesOrderKeys.reduce((a, b) => a + sumHold(window.salesOrderDataFilteredByStyleNumber[b], cadReducer.defaultAccount) , 0);
      window.holdTotalDollar = salesOrderKeys.reduce((a, b) => a + sumHoldDollar(window.salesOrderDataFilteredByStyleNumber[b], cadReducer.defaultAccount) , 0);
      window.worksheetTotal = salesOrderKeys.reduce((a, b) => a + sumWorksheet(window.salesOrderDataFilteredByStyleNumber[b], cadReducer.defaultAccount) , 0);
      window.worksheetTotalDollar = salesOrderKeys.reduce((a, b) => a + sumWorksheetDollar(window.salesOrderDataFilteredByStyleNumber[b], cadReducer.defaultAccount) , 0);
    }
  }

  that.clearCadCard()

  that.setState({
    sliceNum: 200
  })

  that.forceUpdate()
}

const marginIsGood = (margins, orderMargin) => {
  if (margins.length == 0) return true
  if (orderMargin == null) return false

  let marginStatus = "Low"
  if (orderMargin > 0.2) marginStatus = "Avg"
  if (orderMargin >= 0.35) marginStatus = "Good"
  if (orderMargin >= 0.45) marginStatus = "Great"

  return margins.includes(marginStatus)
}

const updateCadsSalesHistory = (myState, cadReducer, that) => {
  let {reps} = myState
  let filteringRep = reps.length > 0

  var dateFromData = cadReducer.dateFrom.split('/');
  var dateFromDate = new Date(dateFromData[2],dateFromData[0]-1,dateFromData[1]);
  var dateToData = cadReducer.dateTo.split('/');
  var dateToDate = new Date(dateToData[2],dateToData[0]-1,dateToData[1]);

  window.salesAccountDataFiltered = [];
  window.salesOrderDataFiltered = [];
  window.salesOrderDataFiltered_NoHoldOrWorksheet = [];
  window.salesOrderDataFiltered_Hold = [];
  window.salesOrderDataFiltered_Worksheet = [];

  if (!!window.salesAccountData) {
    var salesAccountKeys = Object.keys(window.salesAccountData);

    for (var i=0; i<salesAccountKeys.length; i++) {
      var salesAccountKey = salesAccountKeys[i];

      var invoicesFiltered = [];
      var invoices = window.salesAccountData[salesAccountKey];
      for (var j=0; j< invoices.length; j++) {
        var invoice = invoices[j];
        var invoiceDateData = invoice.date.split('-');
        var invoiceDate = new Date(invoiceDateData[0],invoiceDateData[1]-1,invoiceDateData[2]);

        let rep = invoice.rep
        if (rep == null) {
          rep = 'UNDEFINED'
        }

        if (invoiceDate >= dateFromDate && invoiceDate <= dateToDate && (filteringRep ? reps.indexOf(rep) > -1 : true) && marginIsGood(cadReducer.margins, invoice['margin'])) {
          invoicesFiltered.push(invoice)
        }
      }

      if (invoicesFiltered.length > 0) {// set filtered invoices to keys in window.salesAccountDataFiltered
        window.salesAccountDataFiltered[salesAccountKey] = invoicesFiltered
      }

    }
  }

  //also do for sales orders

  if (!!window.salesOrderData) {
    var salesOrderKeys = Object.keys(window.salesOrderData);

    for (var i=0; i<salesOrderKeys.length; i++) {
      var salesOrderKey = salesOrderKeys[i];

      var invoicesFiltered = [];
      var invoices = window.salesOrderData[salesOrderKey];
      for (var j=0; j< invoices.length; j++) {
        var invoice = invoices[j];
        var invoiceDateData = invoice.date.split('-');
        var invoiceDate = new Date(invoiceDateData[0],invoiceDateData[1]-1,invoiceDateData[2]);

        let rep = invoice.rep
        if (rep == null) {
          rep = 'UNDEFINED'
        }

        if (invoiceDate >= dateFromDate && invoiceDate <= dateToDate && (filteringRep ? reps.indexOf(rep) > -1 : true) && marginIsGood(cadReducer.margins, invoice['margin'])) {
          invoicesFiltered.push(invoice)

          if (!invoice.hold && !invoice.worksheet) {
            window.salesOrderDataFiltered_NoHoldOrWorksheet[salesOrderKey] = true;
          } else if (!!invoice.hold) {
            window.salesOrderDataFiltered_Hold[salesOrderKey] = true;
          } else {
            window.salesOrderDataFiltered_Worksheet[salesOrderKey] = true;
          }
        }
      }

      if (invoicesFiltered.length > 0) {// set filtered invoices to keys in window.salesAccountDataFiltered
        window.salesOrderDataFiltered[salesOrderKey] = invoicesFiltered;
      }

    }
  }

  //get list of accountStyleNumbers from filtered list

  var accountStyleNumbers = [];
  if (window.salesAccountData != null && ['PO', 'Hold', 'Worksheet'].indexOf(cadReducer.cadStatus) == -1) {
    accountStyleNumbers = accountStyleNumbers.concat(Object.keys(window.salesAccountDataFiltered));
  }
  if (window.salesOrderData != null && ['Shipped', 'Hold', 'Worksheet'].indexOf(cadReducer.cadStatus) == -1) {
    accountStyleNumbers = accountStyleNumbers.concat(Object.keys(window.salesOrderDataFiltered_NoHoldOrWorksheet));
  }
  if (window.salesOrderData != null && ['Shipped', 'PO', 'Worksheet'].indexOf(cadReducer.cadStatus) == -1) {
    accountStyleNumbers = accountStyleNumbers.concat(Object.keys(window.salesOrderDataFiltered_Hold));
  }
  if (window.salesOrderData != null && ['Shipped', 'PO', 'Hold'].indexOf(cadReducer.cadStatus) == -1) {
    accountStyleNumbers = accountStyleNumbers.concat(Object.keys(window.salesOrderDataFiltered_Worksheet));
  }

  //TAKE UNIQUE
  var accountStyleNumbersUnique = [];
  for (var i=0;i<accountStyleNumbers.length;i++) {
    var accountStyleNumber = accountStyleNumbers[i];
    if (accountStyleNumbersUnique.indexOf(accountStyleNumber) == -1) {
      accountStyleNumbersUnique.push(accountStyleNumber);
    }
  }
  accountStyleNumbers = accountStyleNumbersUnique;

  var missingAccountStyleNumbers = accountStyleNumbers;

  let cadsSalesHistory = [];
  for (let i=0; i < window.cads.length; i++) {
    let cad = window.cads[i]

    if (accountStyleNumbers.indexOf(cad.style_number.flatUpcase()) > -1) {
      cadsSalesHistory.push(cad)
      missingAccountStyleNumbers.splice( missingAccountStyleNumbers.indexOf(cad.style_number.flatUpcase()), 1 );
    }
  }

  window.missingAccountStyleNumbers = missingAccountStyleNumbers;

  that.clearCadCard()

  that.setState({
    cadsSalesHistory: cadsSalesHistory,
    missingStyles: missingAccountStyleNumbers.length
  });
}

export {updateCadsSalesHistoryByStyleNumber, updateCadsSalesHistory}
