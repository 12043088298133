import React from 'react'
import {Modal, Input, Dropdown, Button} from 'semantic-ui-react'
import {isCustomer} from '../cads/is-role'

const markAsBestSellerModals = (myState, bestSellerModalClose, poorSellerAccountOnChange, averageSellerAccountOnChange, bestSellerAccountOnChange, saveBestPoorSeller) => {
  return (
    <React.Fragment>
      <Modal size={'mini'} open={myState.poorSellerModalOpen} onClose={bestSellerModalClose} closeIcon>
        <Modal.Header>Mark as Poor Seller</Modal.Header>
        <Modal.Content>
          <Input id="mark-as-poor-seller-date" fluid type="date" style={{marginBottom: '20px'}} label="Date" />
          {
            isCustomer()
            ?
            null
            :
            <Dropdown placeholder='Sales History' fluid selection search options={myState.salesHistoryAccounts.filter(x => x.value != null && !x.value.startsWith('ALL '))} onChange={poorSellerAccountOnChange}/>
          }
        </Modal.Content>
        <Modal.Actions>
          <Button id="mark-as-poor-seller-button" onClick={saveBestPoorSeller.bind(null, 'poor')} primary>
            Mark as Poor Seller
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal size={'mini'} open={myState.averageSellerModalOpen} onClose={bestSellerModalClose} closeIcon >
        <Modal.Header>Mark as Average Seller</Modal.Header>
        <Modal.Content>
          <Input id="mark-as-average-seller-date" fluid type="date" style={{marginBottom: '20px'}} label="Date" />
          {
            isCustomer()
            ?
            null
            :
            <Dropdown placeholder='Contact' fluid selection search options={myState.salesHistoryAccounts.filter(x => x.value != null && !x.value.startsWith('ALL '))} onChange={averageSellerAccountOnChange}/>
          }
        </Modal.Content>
        <Modal.Actions>
          <Button id="mark-as-average-seller-button" onClick={saveBestPoorSeller.bind(null, 'average')} primary>
            Mark as Average Seller
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal size={'mini'} open={myState.bestSellerModalOpen} onClose={bestSellerModalClose} closeIcon >
        <Modal.Header>Mark as Best Seller</Modal.Header>
        <Modal.Content>
          <Input id="mark-as-best-seller-date" fluid type="date" style={{marginBottom: '20px'}} label="Date" />
          {
            isCustomer()
            ?
            null
            :
            <Dropdown placeholder='Contact' fluid selection search options={myState.salesHistoryAccounts.filter(x => x.value != null && !x.value.startsWith('ALL '))} onChange={bestSellerAccountOnChange}/>
          }
        </Modal.Content>
        <Modal.Actions>
          <Button id="mark-as-best-seller-button" onClick={saveBestPoorSeller.bind(null, 'best')} primary>
            Mark as Best Seller
          </Button>
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  )
}

export {markAsBestSellerModals}
