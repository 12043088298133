import React from 'react'

const bestSellerBadge = (defaultAccount, cad) => {
  let {salesAccountBestSellers} = window
  let {best_seller_total} = cad

  if (defaultAccount.length > 0) {
    if (salesAccountBestSellers != undefined && salesAccountBestSellers.includes(cad.id)) {
      return content
    }
  } else {
    if (best_seller_total != null && best_seller_total > 0) {
      return content
    }
  }
}

const content = (
  <img src="https://erp-rails-usa-production.s3.us-east-1.amazonaws.com/images/best_seller_badge.png"
    style={{width: '90px', height: 'auto', position: 'absolute', left: '-20px', top: '41px'}}
  />
)

export default bestSellerBadge
