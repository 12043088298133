import React from 'react'

const DateFromToInputs = (fromDateOnChange, toDateOnChange) => {
  return (
    <span style={{width: '200px', display: 'inline-block', verticalAlign: 'top', position: 'relative', bottom: '16px', marginLeft: '22px'}}>
      <div>
        From <input type="date" onChange={fromDateOnChange} />
      </div>
      <div style={{paddingTop: '5px'}}>
        To <input type="date" onChange={toDateOnChange} />
      </div>
    </span>
  )
}

export default DateFromToInputs
