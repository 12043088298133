import Utils from '../../modules/utils'
import {sortStatus} from './sort-status.js'

const stringFields = ['forwardAdminPinEmail', 'forwardCancelVpoEmail']
const booleanFields = ['sendCancelVpoAlert', 'canSeePrice', 'canSeeContainerTotals', 'canSeeCost', 'canEditCads', 'canDeleteCads', 'canImpersonateUsers', 'superadmin']
const arrayFields = ['brand', 'division', 'category', 'subCategory', 'status']

const saveCustomAccessData = (customAccessData, userId, callbackFunc) => {
  const url = 'custom_access'
  let data = {data: alwaysRequireImmeadiate(deleteEmptyKeys(customAccessData)), userId}
  Utils.post(url, data, callbackFunc)
}

const alwaysRequireImmeadiate = (customAccessData) => {
  if (customAccessData['status'] != null && !customAccessData['status'].includes('Immediate')) {
    customAccessData['status'] = sortStatus(customAccessData['status'].concat(['Immediate']))
  }

  return customAccessData
}
const deleteEmptyKeys = (customAccessData) => {
  arrayFields.forEach((field) => {
    if (customAccessData[field] != null && customAccessData[field].length == 0) {
      delete customAccessData[field]
    }
  })

  booleanFields.forEach((field) => {
    if (customAccessData[field] === false) {
      delete customAccessData[field]
    }
  })

  stringFields.forEach((field) => {
    if (customAccessData[field] === '') {
      delete customAccessData[field]
    }
  })


  return customAccessData
}

export {saveCustomAccessData, booleanFields, stringFields}
